.projectsSection {
    border-top: solid 1px #e2e0e0;
    min-height: 1700px;
}

.projectsTitle {
    text-align: center;

}

.projectsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none;

}

.project {
    width: 350px;
    padding: 50px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

}

.project>a {
    text-decoration: none;
    color: inherit;
}

.project>a:hover {
    text-decoration: underline;
    color: #8968ac;
}

.icon {
    border-radius: 100%;
    height: 200px;
    width: 200px;
    border: 1px solid #636363;
    object-fit: contain;
}

.icon:hover {
    border: 3px solid #8968ac;
}

.iconContainer {
    height: 205px;
    width: 205px;
    align-self: center;
}

.buttonContainer {
    display: flex;
    justify-content: center;
}

button {
    background-color: white;
    border: 1px solid #636363;
    border-radius: 5px;
    color: #636363;
    font-size: x-large;
    margin-bottom: 20px;
    padding: 10px;
    width: 275px;
}

button:hover {
    background-color: #e7e3e3;
    cursor: pointer;
}

@media only screen and (max-width: 500px) {
    .project>p {
        text-align: left;
    }
}