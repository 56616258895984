.mainSection {
    min-height: 400px;
    padding: 93px 120px 56px 120px;
}

.spotlight {
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.hRule {
    background: linear-gradient(to right, #8968ac, #fcc5f9);
    border: none;
    height: 3px;
}

.content {
    display: flex;
    flex-direction: column;
    width: 512px;
}

.content > p {
    font-size: xx-large;
}

.image {
    border: 1px solid #e7e1e1;
    border-radius: 50%;
    height: 300px;
    margin-top: 10px;
    object-fit: cover;
    width: 300px;
   
}
