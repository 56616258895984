footer {
    color: white;
    display: flex;
    font-size: large;
    flex-direction: column;
    margin: auto;
    text-align: center;
    max-width: 500px;
}

.icons {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    padding: 0;
    height: 100px;
}

.icons>li>a {
    text-decoration: none;
}

.socialIcon>i {
    font-size: 70px;
    color: white;
    margin: 20px;
    border: 1px solid white;
    border-radius: 100%;
    padding: 15px;
}

.socialIcon>i:hover {
    border: 3px solid white;
}

.linksIcon {
    border: 1px solid white;
    border-radius: 100%;
    margin: 15px;
    position: relative;
    top: 1px;
}

.linksIcon:hover {
    border: 3px solid white;
}

.linksText>span {
    font-size: x-large;
    font-weight: 300;
}

.linksText>p {
    font-size: small;
    margin-top: 30px;
    padding-bottom: 100px;
}