body {
    background-image: linear-gradient(#8968ac, #f99ef4);
    max-width: 1200px;
    margin: auto;
    font-family: "Anek Latin", sans-serif;
    color: #636363;
}

.contentWrapper {
    background-color: white;
    border-radius: 10px;
}

.horizontalRule {
    background: linear-gradient(to right, #8968ac, #fcc5f9);
    border: none;
    height: 3px;
    margin: auto;
    width: 200px;
}

p {
    font-size: larger;
    font-weight: 400;
}

h1 {
    font-size: 50px;
}

h2 {
    font-size: 40px;
}

h3 {
    font-size: 40px;
}
