.header {
    color: whitesmoke;
    font-family: "Anek Latin", sans-serif;
    font-weight: 100;
    margin: 130px auto 75px auto;
    text-align: center;
    width: 300px;
}

.header > p {
    font-size: x-large;
}
